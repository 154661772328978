import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

function matchStart(params, data) {
  // If there are no search terms, return all of the data
  if ($.trim(params.term) === '') {
    return data;
  }

  // Skip if there is no 'children' property
  if (typeof data.children === 'undefined') {
    return null;
  }

  // `data.children` contains the actual options that we are matching against
  var filteredChildren = [];
  $.each(data.children, function (idx, child) {
    if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
      filteredChildren.push(child);
    }
  });

  // If we matched any of the timezone group's children, then set the matched children on the group
  // and return the group object
  if (filteredChildren.length) {
    var modifiedData = $.extend({}, data, true);
    modifiedData.children = filteredChildren;

    // You can return modified objects from here
    // This includes matching the `children` how you want in nested data sets
    return modifiedData;
  }

  // Return `null` if the term should not be displayed
  return null;
}

window.addEventListener('DOMContentLoaded', () => {
   $("[data-component='select2']").select2({
    placeholder: $(this).attr("data-placeholder") || 'Choose one',
    multiple: $(this).attr("multiple"),
    
    ...($(this).attr("allow-clear") ? { allowClear: $(this).attr("allow-clear") } : null),
    ...($(this).attr("maximum-selection-length") ? { maximumSelectionLength: $(this).attr("maximum-selection-length") } : null ),
    ...($(this).attr("allow-search") ? { matcher: matchStart } : null ),
  })
})