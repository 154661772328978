import { Controller } from "@hotwired/stimulus"
// import grapesjs from "grapesjs"
// Connects to data-controller="visual-editor"
import techtown from "../grapesjs-techtown-block/src/";
export default class extends Controller {
  static values = {pageslug: String, accesstoken: String}
  csrfToken() {
    const metaTag = document.querySelector("meta[name='csrf-token']");
    return metaTag ? metaTag.content : "";
  }
  async connect() {
    console.log('Connected Visual Editor')

    const escapeName = (name) => `${name}`.trim().replace(/([^a-z0-9\w-:/]+)/gi, '-');
    
    this.visualEditor = grapesjs.init({
      container: '#gjs',
      plugins: ["grapesjs-ga", "grapesjs-plugin-forms",
      techtown, "grapesjs-tailwind"],
      pluginsOpts: {
        'grapesjs-tailwind': { 
          cover: ''
         }
      },
      fromElement: true, 
      width: 'auto',
      height: '100vh',
      selectorManager: { escapeName },
      storageManager: {
        type: 'remote',
        autosave: true,
        autoload: true,
        stepsBeforeSave: 1,
        options: {
          remote: {
            headers: {
              'X-CSRF-Token': this.csrfToken()
            },
            urlStore: `/113344/en/pages/${this.pageslugValue}/update_editor`,
            urlLoad: `/113344/en/pages/${this.pageslugValue}/html`,
            fetchOptions: opts => (opts.method === 'POST' ?  { method: 'PATCH' } : {}),
            onLoad: result => result.data
          }
        }
      },
      assetManager:{
        headers: {
          'X-CSRF-Token': this.csrfToken(),
        },
        upload: '/113344/pages/upload_image',
        uploadName: 'image'
      }
    })

    const assetManager = this.visualEditor.AssetManager;
    const assets = await fetch(`/113344/en/asset_images?slug=${this.pageslugValue}`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.csrfToken()
      }
    }).then(response => response.json())
    .then(response => {
      return response
    })
    
    assetManager.add(assets)
    assetManager.render()
    const panelManager = this.visualEditor.Panels
    panelManager.addButton("options", {
      id: "update-theme",
      className: "fa fa-adjust",
      command: "open-update-theme",
      attributes: {
        title: "Update Theme",
        "data-tooltip-pos": "bottom"
      }
    });
    // console.log(panelManager.getPanel())
    panelManager.addButton('options', {
      id: 'show-json',
      className: 'fa fa-heart',
      command(editor) {
        console.log({css: editor.getHtml()})
        // editor.Modal.setTitle('Components JSON')
        //   .setContent(`<textarea style="width:100%; height: 250px;">${editor.getComponents()}</textarea>`).open();
      },
      context: 'show-json',
      attributes: { title: 'Some title'},
      active: false,
    })
  }
}

